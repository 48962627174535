import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// JSON-LD

const SDArticle = ({ title, image, datepub, datemod }) => {

    // feature: article
    // https://schema.org/Article   
    // https://developers.google.com/search/docs/data-types/article

    const { site } = useStaticQuery(
    graphql`
    query {
        site: file(relativePath: {eq: "settings/settings-sitemeta.md"}) {
            id
            childMarkdownRemark {
              frontmatter {
                defaultUrl: siteurl
                defaultTitle: title
                defaultDescription: description
                defaultAuthor: author
                defaultImage: defimage {
                  publicURL
                }
                defaultLogo: logo {
                  publicURL
                }
              }
            }
          }
    }
  `)

    const {
        defaultUrl,
        defaultTitle,
        // defaultDescription,
        defaultAuthor,
        defaultImage,
        defaultLogo,
    } = site.childMarkdownRemark.frontmatter

    const curISODateTime = new Date().toISOString()

    const sData = {
        url: defaultUrl, // Base URL
        headline: title || defaultTitle, // Max 110 characters
        image: image || defaultImage.publicURL, // No trailing slash
        datePublished: datepub || curISODateTime, // ISO format
        dateModified: datemod || curISODateTime, // ISO format
        authorType: "Organization", // Person or Organization
        authorName: defaultAuthor, // Text
        publisherType: "Organization", // Organization
        publisherName: defaultAuthor, // Text
        publisherLogo: defaultLogo.publicURL, //Logo URL
    }

    const writeData = () => {
        const scriptData = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": sData.url
            },
            "headline": sData.headline,
            "image": [sData.url+sData.image],
            "datePublished": sData.datePublished,
            "dateModified": sData.dateModified,
            "author": {
                "@type": sData.authorType,
                "name": sData.authorName
            },
            "publisher": {
                "@type": sData.publisherType,
                "name": sData.publisherName,
                "logo": {
                    "@type": "ImageObject",
                    "url": sData.url+sData.publisherLogo
                }
            }
        }
    )
        return(
            scriptData
        )}
    return(
        <>
        <Helmet>
            <script type="application/ld+json">
                {writeData()}
            </script>
        </Helmet>
        </>
    )
}

export default SDArticle