import React from "react"
import Layout from "../components/layout/Layout"
import HeroImage from "../components/HeroImage"
import SEO from "../components/SEO"
import { graphql, Link } from 'gatsby'
import LogoSprite from "../images/svg/LogoSprite"
import { ImHome } from "react-icons/im"
import SDArticle from "../components/StructuredData/SDArticle"

const BlogPost = ( {data, location, pageContext} ) => {
    const {next, prev} = pageContext
    const post = data.postdata
    const sitemeta = data.sitemetasettings.childMarkdownRemark

    return (
        <Layout location={location}>
            <SDArticle title={post.frontmatter.title} image={post.frontmatter.featureimage.publicURL} datepub={post.frontmatter.datepub} datemod={post.frontmatter.datepub} />
            <SEO title={post.frontmatter.seotitle} description={post.frontmatter.seometadesc} image={post.frontmatter.featureimage.publicURL}/>
            <HeroImage markdown={ post } />
            <section className="container mx-auto">
                <div className="text-left px-4 pt-4 pb-4">
                    <ul className="text-left text-xs md:text-base">
                        <li className="inline-block">
                            <Link to="/">
                                <span className="inline-block pr-2"><ImHome /></span>
                                <span className="px-1">Home</span>
                            </Link>
                        </li>
                        <li className="inline-block"><span>·</span></li>
                        <li className="inline-block">
                            <Link to="/blog/">
                                <span className="px-1">Blog</span>
                            </Link>
                        </li>
                        <li className="inline-block"><span>·</span></li>
                        <li className="inline-block">
                            <span className="px-1">{post.frontmatter.title}</span>
                        </li>
                    </ul>
                </div> 
                <div className="mx-auto px-2 md:px-8 flex flex-col-reverse lg:flex-row">
                    <div className="p-6 lg:w-2/3">
                        <h1>{post.frontmatter.title}</h1>
                        <div className="text-left lg:text-justify cmscontent" dangerouslySetInnerHTML={{ __html: post.html }} />
                        <div className="flex flex-col justify-between sm:flex-row pt-8">
                            <div className="text-left">
                                {prev && <>
                                    <Link className="text-sm md:text-base" to={prev.fields.slug}>Previous</Link>
                                    <p><Link className="text-sm md:text-base text-primary-action" to={prev.fields.slug}>{prev.frontmatter.title}</Link></p>
                                </>}
                            </div>
                            <div className="text-right">
                            {next && <>
                                    <Link className="text-sm md:text-base" to={next.fields.slug}>Next</Link>
                                    <p><Link className="text-sm md:text-base text-primary-action" to={next.fields.slug}>{next.frontmatter.title}</Link></p>
                                </>}
                            </div>
                        </div>
                    </div>
                    <div className="px-6 pt-6 pb-0 lg:pb-6 w-2/3 lg:w-1/3 flex flex-row lg:flex-col items-start">
                        <LogoSprite />
                        <div className="text-xs md:text-base py-0 lg:py-2 font-bold pl-2 lg:pl-0">{sitemeta.frontmatter.author}<p>{post.frontmatter.date}</p></div>
                        {/* <div>{post.frontmatter.date}</div> */}
                        <p className="text-xs hidden lg:inline-block">{sitemeta.frontmatter.ogdescription}</p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default BlogPost

export const query = graphql`
query($slug: String!) {
    postdata: markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        frontmatter {
            title
            datepub: date
            date(formatString: "DD MMMM, YYYY")
            seotitle
            seometadesc
            featureimagealt
            featureimage {
                childImageSharp {
                    fluid(maxWidth: 4000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp 
                    }
                }
                extension
                publicURL
            }
    }
  }
  sitemetasettings: file(relativePath: {eq: "settings/settings-sitemeta.md"}) {
    id
    childMarkdownRemark {
      frontmatter {
        author
        ogdescription
      }
    }
  }
}
`